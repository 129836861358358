import React from 'react';
// import Favicon from 'react-favicon';
import { Routing } from './Routing';
import { ContextResponseProvider } from './contexts/ContextResponseProvider';
import { ContextDataProvider } from './contexts/ContextDataProvider';
import { ContextSnackbarProvider } from './contexts/ContextSnackbarProvider';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { SocialBox } from './components/SocialBox/SocialBox';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#42dca3',
      contrastText: '#fff',
    }
  }
});

export const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      {/* <Favicon url={logo} /> */}
      <ContextDataProvider>
        <ContextSnackbarProvider>
          <ContextResponseProvider>
            <SocialBox />
            <Routing />
          </ContextResponseProvider>
        </ContextSnackbarProvider>
      </ContextDataProvider>
    </MuiThemeProvider>
  );
};
