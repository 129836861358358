import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { ContextSnackbar } from '../../../../contexts/ContextSnackbarProvider';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { api } from '../../../../helpers/CONSTS';
import './ConfirmWrapper.scss';

export const ConfirmWrapper = () => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { snackbar, setSnackbar } = useContext(ContextSnackbar);
  const { map } = useContext(ContextMap);

  const onConfirm = async () => {
    setLoader(true);
    try {
      const { data } = await axios.post(`${api}/payment/add-cart/`, map);

      window.location.replace(`https://kreatywnywarsztat.pl/koszyk/?add-to-cart=${data.id}`);
    }
    catch (err) {
      setSnackbar({
        ...snackbar,
        text: err?.response?.data?.errors ? `<ul>
          ${err.response.data.errors.map(elem => `<li>${elem.param} - ${elem.msg}</li>`).join('')}
        </ul>` : 'Błąd wewnętrzny',
        show: true,
        type: 'error',
      });
    }
    setLoader(false);
  }

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle component="h3" variant="h6">
          To już ostatni krok
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Sprawdź czy wszystko jest zgodnie z Twoim pomysłem. Jeśli potrzebujesz coś poprawić to kliknij w przycisk <span className="font-weight-bold">ANULUJ</span>, jeśli jesteś gotowy by zamówić to kliknij w <span className="font-weight-bold">PRZEJDŹ DO KOSZYKA</span> by sfinalizować swoje zamówienie.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={onClose}
            className="standard-paragraph cursor-pointer w-100 py-3 px-2 mb-2 secondary-font text-uppercase font-weight-regular border-light transparent-bg light-gray-color"
          >
            Anuluj
          </button>

          <button
            disabled={loader}
            onClick={onConfirm}
            className="standard-paragraph cursor-pointer w-100 py-3 px-2 mb-2 secondary-font text-uppercase font-weight-regular border-light almost-black-bg white-color"
          >
            Przejdź do koszyka
            {loader && <CircularProgress className="loader" size={34} />}
          </button>
        </DialogActions>
      </Dialog>
      <button
        onClick={onOpen}
        className="small-paragraph almost-black-bg h-100 border-none cursor-pointer secondary-font text-uppercase font-weight-regular white-color px-4 py-3"
      >
        Zatwierdź
      </button>
    </>
  );
};
