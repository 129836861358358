import React from 'react';
import './ControlContainer.scss';
import { MapAutocomplete } from './controls/MapAutocomplete';
import { DescriptionFields } from './controls/DescriptionFields';
import { StylePicker } from './controls/StylePicker';
import { TypePicker } from './controls/TypePicker';
import { SizePicker } from './controls/SizePicker';
import { OptionPicker } from './controls/OptionPicker';
import { BackgroundPicker } from './controls/BackgroundPicker';
import { PitchPicker } from './controls/PitchPicker';
import { BearingPicker } from './controls/BearingPicker';
import { ZoomPicker } from './controls/ZoomPicker';
import { OrientationPicker } from './controls/OrientationPicker';
import { LabelsSwitch } from './controls/LabelsSwitch';
import { LayoutPicker } from './controls/LayoutPicker';
import { PatternPicker } from './controls/PatternPicker';
import { HuePicker } from './controls/HuePicker';

export const ControlContainer = () => {

  return (
    <div className="control-container py-2">
      <MapAutocomplete />
      <LayoutPicker />
      <StylePicker />
      <PatternPicker />
      {/* <HuePicker /> */}
      <LabelsSwitch />
      <DescriptionFields />
      <PitchPicker />
      <BearingPicker />
      <ZoomPicker />
      <TypePicker />
      <OrientationPicker />
      <OptionPicker />
      <SizePicker />
      <BackgroundPicker />

    </div>
  );
}