import React, { useContext } from 'react';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { backgrounds } from '../../../../helpers/CONSTS';

export const BackgroundPicker = () => {
  const { setMap, map } = useContext(ContextMap);

  const onSelect = (elem) => {
    const newMap = {
      ...map,
      background: elem,
    };

    setMap(newMap);
  };

  return (
    <section className="control-panel pb-5 mb-5 pb-lg-0 mb-lg-0 border-none">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Zobacz swoją mapę na innym tle.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Sprawdź jak produkt może się prezentować na innym tle.</p>
      <div className="container-fluid p-0">
        <div className="row">
          {backgrounds.map((elem, key) => (
            <div key={key} className="col-lg-4 col-sm-4 col-6 mb-2">
              <button
                className={`standard-paragraph cursor-pointer w-100 h-100 py-3 px-2 secondary-font text-uppercase font-weight-regular border-light ${map.background?.name === elem.name ? 'almost-black-bg white-color' : 'transparent-bg light-gray-color'}`}
                onClick={() => onSelect(elem)}
              >
                {elem.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};