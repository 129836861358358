import React, { createContext, useState } from 'react';

export const ContextResponse = createContext(null);

export const ContextResponseProvider = ({ children }) => {
  let [response, setResponse] = useState(false);

  return (
    <ContextResponse.Provider value={{
      setResponse,
      response,
    }}>
      {children}
    </ContextResponse.Provider>
  )
};
