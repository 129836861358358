import React, { useContext } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import './InfoChip.scss';

export const InfoChip = () => {

  return (
    <div className="info-chip d-flex">
      <InfoIcon className="mr-1" />
      <p className="standard-paragraph text-justify">Przed realizacją zamówienia Klient zawsze otrzymuje ostateczny projekt do akceptacji, dlatego zwróć uwagę na poprawność danych w kolejnych krokach zamówienia.</p>
    </div>
  );
}