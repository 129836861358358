import React, { useContext } from 'react';
import { Slider } from '@material-ui/core';
import { ContextMap } from '../../../../contexts/ContextMapProvider';

export const BearingPicker = () => {
  const { setMap, map } = useContext(ContextMap);

  const onChange = (e, newValue) => {
    const newMap = {
      ...map,
      bearing: newValue
    }

    setMap(newMap)
  } 

  return (
    <section className="control-panel">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Wybierz azymut.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Dzięki tej opcji możesz zrobić unikalną mapę gdzie północ jest tam gdzie południe.</p>
      <div className="container-fluid px-2">
        <div className="row">
          <div className="col-lg-12">
            {map.type && (
              <Slider
                marks={[
                  {
                    value: 0,
                    label: 'N',
                  },
                  {
                    value: 180,
                    label: 'S',
                  },
                  {
                    value: -180,
                    label: 'S',
                  },
                  {
                    value: 90,
                    label: 'E',
                  },
                  {
                    value: -90,
                    label: 'W',
                  },
                ]}
                value={map.bearing}
                onChange={onChange}
                valueLabelFormat={(value) => `${value}°`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                min={-180}
                max={180}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}