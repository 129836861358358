import React, { useContext } from 'react';
import { TextField } from '@material-ui/core'

import { ContextMap } from '../../../../contexts/ContextMapProvider';

export const DescriptionFields = () => {
  const { setMap, map } = useContext(ContextMap);

  const onChange = e => {
    const { value, name } = e.target;

    const newMap = {
      ...map,
      [name]: value
    }

    setMap(newMap);
  }

  return (
    <section className="control-panel boder-top-none pt-1">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Własny tekst.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Dodając podpisy możesz jeszcze bardziej spersonalizować swoją mapę - możesz je także skasować, dzięki temu zyskasz jeszcze więcej przestrzeni.</p>

      <div className="form-group mb-10">
        <TextField disabled={!map.labels} fullWidth margin="dense" name="city" label="Miasto" variant="outlined" value={map.city} onChange={(e) => onChange(e)} />
      </div>
      <div className="form-group mb-10">
        <TextField disabled={!map.labels} fullWidth margin="dense" name="country" label="Kraj" variant="outlined" value={map.country} onChange={(e) => onChange(e)} />
      </div>
      <div className="form-group mb-10">
        <TextField disabled={!map.labels} fullWidth margin="dense" name="text" label="Opis" variant="outlined" value={map.text} onChange={(e) => onChange(e)} />
      </div>
    </section>
  );
}