import React, { useContext } from 'react';
import { DetailDialog } from './DetailDialog';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { ContextData } from '../../../../contexts/ContextDataProvider';
import { Translate } from '../../../../components/Translate';

export const OptionPicker = () => {
  const { formData, translates } = useContext(ContextData);
  const { setMap, map } = useContext(ContextMap);

  const onSelect = elem => {
    const newMap = {
      ...map,
      option: elem
    }

    setMap(newMap)
  } 

  return (
    <section className="control-panel">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Wybierz opcje.</h2>
      <DetailDialog />
      <div className="container-fluid p-0">
        <div className="row">
          {(formData.length && map.type) && formData.find(({ name }) => name === map.type).options.map((elem, key) => (
            <div key={key} className="col-lg-4 col-sm-4 col-6 mb-2">
              <button className={`small-paragraph cursor-pointer w-100 py-3 px-2 mb-2 secondary-font h-100 text-uppercase font-weight-regular border-light ${map.option === elem ? 'almost-black-bg white-color' : 'transparent-bg light-gray-color'}`} onClick={() => onSelect(elem)}><Translate translates={translates} text={elem} /></button>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}