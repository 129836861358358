import React, { useContext } from 'react';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { ContextData } from '../../../../contexts/ContextDataProvider';

export const SizePicker = () => {
  const { formData } = useContext(ContextData);
  const { setMap, map } = useContext(ContextMap);

  const onSelect = elem => {
    const newMap = {
      ...map,
      size: elem
    }

    setMap(newMap)
  }

  console.log(map.size);

  return (
    <section className="control-panel">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-15">Wybierz rozmiar.</h2>
      <div className="container-fluid p-0">
        <div className="row">
          {(formData.length && map.type) && formData.find(({ name }) => name === map.type).sizes.map((elem, key) => (
            <div key={key} className="col-lg-4 col-sm-4 col-6 mb-2">
              <button className={`small-paragraph cursor-pointer h-100 w-100 py-3 px-2 mb-2 secondary-font text-uppercase font-weight-regular border-light ${(elem.width === map.size?.width && elem.height === map.size?.height) ? 'almost-black-bg white-color' : 'transparent-bg light-gray-color'}`} onClick={() => onSelect(elem)}>{elem.width}/{elem.height} cm</button>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}