import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './SocialBox.scss';

export const SocialBox = () => {
  const history = useHistory();

  return (
    <section className="social-box py-3 d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-flex align-items-center">

            <button
              className={`standard-paragraph mb-2 mb-md-0 d-flex align-items-center cursor-pointer secondary-font text-uppercase font-weight-regular border-none almost-black-bg white-color transparent-bg light-gray-color`}
              onClick={() => history.goBack()}
            >
              <ArrowBackIosIcon />
              Powrót
            </button>
          </div>
          <div className="col-md-9 text-left text-sm-right justify-content-start justify-content-md-end d-flex align-items-center">
            <p className="solid-gray-color standard-paragraph letter-spacing-1-5">
              infolinia: <a className="light-green-color d-block d-sm-inline-block" href="tel:+48530933786">+48 530 933 786</a> <a className="light-green-color d-block d-sm-inline-block" href="mailto:sklep@kreatywnywarsztat.pl">sklep@kreatywnywarsztat.pl</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
