import React, { createContext, useState } from 'react';
import { TYPES, TRANSLATES } from '../helpers/CONSTS';

export const ContextData = createContext(null);

export const ContextDataProvider = ({ children }) => {
  let [formData, setFormData] = useState(TYPES);
  let [translates] = useState(TRANSLATES);

  return (
    <ContextData.Provider value={{
      setFormData,
      formData,
      translates
    }}>
      {children}
    </ContextData.Provider>
  )
};
