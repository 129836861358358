import React from 'react';
import './Creator.scss';

import { ContextMapProvider } from '../../contexts/ContextMapProvider';
import { ProjectContainer } from './ProjectContainer/ProjectContainer';
import { ControlContainer } from './ControlContainer/ControlContainer';
import { SummaryContainer } from './SummaryContainer/SummaryContainer';

export const Creator = () => {
  return (
    <ContextMapProvider>
      <div className="creator-page">
        <div className="container-fluid p-0 m-0">
          <div className="row no-gutters">
            <div className="col-lg-4">
              <ControlContainer />
            </div>
            <div className="col-lg-8 d-flex flex-column justify-content-between">
              {window.innerWidth > 991 && <ProjectContainer />} 
              
              <SummaryContainer />
            </div>
          </div>
        </div>
      </div>
    </ContextMapProvider>
  );
}
