import React, { useContext } from 'react';
import { Slider } from '@material-ui/core';
import { ContextMap } from '../../../../contexts/ContextMapProvider';

export const PitchPicker = () => {
  const { setMap, map } = useContext(ContextMap);

  const onChange = (e, newValue) => {
    const newMap = {
      ...map,
      pitch: newValue
    }

    setMap(newMap)
  } 

  return (
    <section className="control-panel">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Ustaw nachylenie.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Zobacz jak perspektywa postrzegania może dużo zmienić w wyglądzie Twojej mapy.</p>
      <div className="container-fluid px-2">
        <div className="row">
          <div className="col-lg-12">
            {map.type && (
              <Slider
                value={map.pitch}
                marks={[
                  {
                    value: 0,
                    label: '0°',
                  },
                  {
                    value: 20,
                    label: '20°',
                  },
                  {
                    value: 40,
                    label: '40°',
                  },
                  {
                    value: 60,
                    label: '60°',
                  }
                ]}
                onChange={onChange}
                valueLabelFormat={(value) => `${value}°`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={60}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}