import React, { useContext } from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { mapBoxPublicKey } from '../../../../helpers/CONSTS';

export const MapAutocomplete = () => {
  const { setMap, map } = useContext(ContextMap);

  const onSelect = async (place, latitude, longitude, city) => {
    const country = place.split(',').pop().substr(1);

    const newMap = {
      ...map,
      city,
      country,
      zoom: 8,
      latitude: parseFloat(latitude, 10),
      longitude: parseFloat(longitude, 10),
    };

    setMap(newMap);
  };

  return (
    <section className="control-panel pt-0">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Znajdź miejsce.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Podaj adres lub nazwę miejscowości by stworzyć swoją mapę.</p>
      <div className="form-group">
        <MapboxAutocomplete
          placeholder="Szukaj..."
          onSuggestionSelect={(place, lat, lng, text) => onSelect(place, lat, lng, text)}
          publicKey={mapBoxPublicKey}
        />
      </div>
    </section>
  );
}