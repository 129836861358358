import React, { createContext, useState, useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TYPES } from '../helpers/CONSTS';
import { ContextData } from './ContextDataProvider';

const defaultMap = {
  type: 'poster',
  option: 'mattepaper',
  size: {
    price: 47,
    width: 21,
    height: 30,
    multiplier: 14
  },
  latitude: 52.21667,
  longitude: 21.03333,
  labels: true,
  city: 'Warszawa',
  country: 'Poland',
  text: 'Sieć dróg',
  pitch: 0,
  bearing: 0,
  zoom: 11,
  orientation: 'vertical',
  layout: 'layout-1',
  style: 'style-1',
  pattern: 'pattern-1',
  hue: 'hue-2',
  background: {
    name: 'Brak',
    src: '',
  }
}

export const ContextMap = createContext(null);

export const ContextMapProvider = ({ children }) => {
  const location = useLocation();
  let [map, setMap] = useState(defaultMap);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    
    const latitudeParam = parseFloat(query.get('latitude') || '');
    const longitudeParam = parseFloat(query.get('longitude') || '');
    const labelsParam = query.get('labels');
    const cityParam = query.get('city');
    const countryParam = query.get('country');
    const textParam = query.get('text');
    const pitchParam = query.get('pitch');
    const bearingParam = query.get('bearing');
    const zoomParam = parseInt(query.get('zoom') || '');
    const orientationParam = query.get('orientation');
    const layoutParam = query.get('layout');
    const styleParam = query.get('style');
    const patternParam = query.get('pattern');
    const hueParam = query.get('hue');

    setMap({
      ...defaultMap,
      latitude: latitudeParam || defaultMap.latitude,
      longitude: longitudeParam || defaultMap.longitude,
      labelsParam: labelsParam || defaultMap.labels,
      city: cityParam || cityParam === '' ? cityParam : defaultMap.city,
      country: countryParam || countryParam === '' ? countryParam : defaultMap.country,
      text: textParam || textParam === '' ? textParam : defaultMap.text,
      pitch: pitchParam || defaultMap.pitch,
      bearing: bearingParam || defaultMap.bearing,
      zoom: zoomParam || defaultMap.zoom,
      orientation: orientationParam || defaultMap.orientation,
      layout: layoutParam || defaultMap.layout,
      style: styleParam || defaultMap.style,
      pattern: patternParam || defaultMap.pattern,
      hue: hueParam || defaultMap.hue
    });
  }, [])

  return (
    <ContextMap.Provider value={{
      setMap,
      map,
    }}>
      {children}
    </ContextMap.Provider>
  )
};
