import React, { useContext } from 'react';
import ReactMapGL from 'react-map-gl';
import { ContextMap } from '../../../contexts/ContextMapProvider';
import { mapBoxPublicKey, mapStyles2 } from '../../../helpers/CONSTS';
import { InfoChip } from './InfoChip/InfoChip';
import './ProjectContainer.scss';

export const ProjectContainer = () => {
  const { map, setMap } = useContext(ContextMap);

  return (
    <section style={{ backgroundImage: `url(${map.background?.src || ''})` }} className="project-container position-relative">
      <InfoChip />
      <article
        className={`${map.layout} ${map.pattern} ${map.orientation}`}
        style={{ width: map.orientation === 'vertical' ? '520px' : '700px', height: map.orientation === 'vertical' ? '700px' : '520px'  }}
      >
        <div className="clip position-absolute right"></div>
        <div className="clip position-absolute left"></div>
        {map.background?.src === '' && (
          <>
            <aside className="position-absolute bottom">
              <p className="regular-paragraph secondary-font font-bold text-center light-gray-color">{map.orientation === 'vertical' ? map.size?.width : map.size?.height}cm</p>
            </aside>
            <aside className="position-absolute left">
              <p className="regular-paragraph secondary-font font-bold text-center light-gray-color">{map.orientation === 'vertical' ? map.size?.height : map.size?.width}cm</p>
            </aside>
          </>
        )}
        <header>
          <ReactMapGL
            pitch={map.pitch}
            bearing={map.bearing}
            mapboxApiAccessToken={mapBoxPublicKey}
            width={'100%'}
            height={'100%'}
            minZoom={0}
            maxZoom={20}
            mapStyle={mapStyles2[map.style]}
            latitude={map.latitude}
            longitude={map.longitude}
            onViewportChange={(viewport) => setMap({ ...map, ...viewport })}
            zoom={map.zoom}
          />
        </header>
        {map.labels && (
          <footer className="position-absolute">
            <p className="footer-city">{map?.city}</p>
            <p className="footer-country">{map?.country}</p>
            <p className="footer-text">{map?.text}</p>
          </footer>
        )}
      </article>
    </section>
  );
}