import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
// import '../node_modules/bootstrap/scss/bootstrap.scss';
import './assets/scss/style.scss';
import { App } from './App.jsx';

ReactDOM.render((
  <BrowserRouter basename="/React">
    <App />
  </BrowserRouter>
), document.getElementById('root'));
