import React, { useContext } from 'react';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { ContextData } from '../../../../contexts/ContextDataProvider';
import { layouts2 } from '../../../../helpers/CONSTS';
import { Translate } from '../../../../components/Translate';

export const StylePicker = () => {
  const { translates } = useContext(ContextData);
  const { setMap, map } = useContext(ContextMap);

  const selectedLayout = layouts2.find(({ name }) => name === map.layout)

  const onSelect = ({ name}) => {
    const selectedStyle = selectedLayout.styles.find(elem => elem.name === name); 

    const newMap = {
      ...map,
      style: name,
      pattern: selectedStyle.patterns[0],
      hue: selectedStyle.hues[0]
    }

    setMap(newMap)
  } 

  return (
    <section className="control-panel">
      <h2 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">Wybierz styl mapy.</h2>
      <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">Dostosuj swój własny unikalny styl mapy.</p>
      <div className="container-fluid p-0">
        <div className="row">
          {selectedLayout?.styles.map((elem, key) => (
            <div key={key} className="col-lg-4 col-sm-4 col-6 mb-2">
              <button className={`small-paragraph cursor-pointer h-100 w-100 py-3 px-2 mb-2 secondary-font text-uppercase font-weight-regular border-light ${map.style === elem.name ? 'almost-black-bg white-color' : 'transparent-bg light-gray-color'}`} onClick={() => onSelect(elem)}><Translate translates={translates} text={elem.name} /></button>
            </div>
          ))}
        </div>
      </div>
    </section>
  ) 
}