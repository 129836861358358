import React, { useState } from 'react';
import './SummaryContainer.scss';
import { PriceWrapper } from './PriceWrapper/PriceWrapper';
import { ConfirmWrapper } from './ConfirmWrapper/ConfirmWrapper';
import { ProjectDialog } from './ProjectDialog';

export const SummaryContainer = () => (
  <section className="summary-container py-2 px-3 d-flex align-items-center justify-content-lg-end justify-content-between">
    {window.innerWidth < 991 && <ProjectDialog />} 
    {/* <ProjectDialog /> */}
    <PriceWrapper />
    <ConfirmWrapper />
  </section>
);
