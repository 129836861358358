import React, { useState } from 'react';
import './SummaryContainer.scss';
import { Dialog, DialogTitle, DialogContent, IconButton, AppBar, Toolbar } from '@material-ui/core';
import { ProjectContainer } from '../ProjectContainer/ProjectContainer';
import CloseIcon from '@material-ui/icons/Close';
export const ProjectDialog = () => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <button
        onClick={onOpen}
        className="small-paragraph light-green-bg border-none cursor-pointer secondary-font text-uppercase font-weight-regular white-color px-4 px-2 py-3"
      >
        Pokaż projekt
      </button>
      <Dialog
        className="project-dialog"
        maxWidth={'xl'}
        fullScreen 
        scroll={'body'}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar>
          <div className="d-flex justify-content-end">

            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </AppBar>
        <DialogContent>
          <ProjectContainer />
        </DialogContent>
      </Dialog>
    </>
  );
};
