import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ContextMap } from '../../../../contexts/ContextMapProvider';
import { ContextSnackbar } from '../../../../contexts/ContextSnackbarProvider';
import { api } from '../../../../helpers/CONSTS';

export const PriceWrapper = () => {
  const { map } = useContext(ContextMap);
  const { snackbar, setSnackbar } = useContext(ContextSnackbar);
  const [ price, setPrice ] = useState(0);

  const getPrice = async () => {
    try{
      const { data } = await axios.post(`${api}/payment/get-price/`, map);
  
      setPrice(data.price);
    } catch(err) {
      setSnackbar({
        ...snackbar,
        text: err?.response?.data?.errors ? `<ul>
          ${err.response.data.errors.map(elem => `<li>${elem.param} - ${elem.msg}</li>`).join('')}
        </ul>` : 'Błąd wewnętrzny',
        show: true,
        type: 'error',
      });
    }
  }

  useEffect(() => {
    if(map.type && map.style) {
      getPrice()
    }
  }, [map.size, map.type])

  return (
    <p className="font-weight-light small-paragraph light-gray-color text-center text-lg-left mx-2 text-uppercase">
      Cena za projekt: <span className="d-lg-inline-block"><span className="font-weight-bold  regular-header almost-black-color">{price}</span> zł</span>
    </p>
  );
};
