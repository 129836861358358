import React, { createContext, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export const ContextSnackbar = createContext(null);

export const ContextSnackbarProvider = ({ children }) => {
  let [snackbar, setSnackbar] = useState({
    show: false,
    text: 'message',
    type: 'success',
  });

  const handleClose = (event, reason) => setSnackbar({
    ...snackbar,
    show: reason === 'clickaway'
  })

  return (
    <ContextSnackbar.Provider
      value={{
        setSnackbar,
        snackbar,
      }}
    >
      {children}
      <Snackbar
        open={snackbar.show}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbar.type} >
          <div dangerouslySetInnerHTML={{ __html: snackbar.text }}></div>
        </Alert>
      </Snackbar>
    </ContextSnackbar.Provider>
  );
};
