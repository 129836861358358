import React, { useContext, useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core'
import { ContextMap } from '../../../../contexts/ContextMapProvider';

export const LabelsSwitch = () => {
  const { setMap, map } = useContext(ContextMap);
  const [checked, setChecked] = useState(true)

  const onChange = () => {
    const newMap = {
      ...map,
      labels: !checked
    }

    setChecked(!checked)
    setMap(newMap)
  } 

  return (
    <section className="mt-3">

      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-12 mb-2">
            
            <FormControlLabel  
              control={<Switch checked={checked} onChange={onChange} color="primary" />}
              label={(
                <p className="standard-paragraph light-gray-color">Pole na tekst</p>
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}