import background1 from '../assets/images/background-1.jpg';
import background2 from '../assets/images/background-2.jpg';

export const orientations = ['vertical', 'horizontal'];

export const layouts2 = [{
  name: 'layout-1',
  styles: [{
    name: 'style-1',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-2',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-3',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-4',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-5',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-6',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-7',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-8',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-9',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-10',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-11',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-12',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-13',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-14',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-15',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-16',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-17',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }],
},
{
  name: 'layout-2',
  styles: [{
    name: 'style-1',
    patterns: ['pattern-7', 'pattern-1', 'pattern-2', 'pattern-4'],
    hues: ['hue-2']
  }, {
    name: 'style-2',
    patterns: ['pattern-5', 'pattern-1', 'pattern-2', 'pattern-4'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-3',
    patterns: ['pattern-5', 'pattern-1', 'pattern-2', 'pattern-4'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-4',
    patterns: ['pattern-4'],
    hues: ['hue-2']
  }, {
    name: 'style-5',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-6',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-7',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-8',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-9',
    patterns: ['pattern-5', 'pattern-1', 'pattern-2', 'pattern-3', 'pattern-4'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-10',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-11',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-12',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-13',
    patterns: ['pattern-4', 'pattern-1', 'pattern-2', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-14',
    patterns: ['pattern-1', 'pattern-2', 'pattern-3', 'pattern-4', 'pattern-5'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-15',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-16',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-17',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }],
},
{
  name: 'layout-3',
  styles: [{
    name: 'style-1',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-2',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-3',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-4',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-5',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-6',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-7',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-8',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-9',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-10',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-11',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-12',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-2']
  }, {
    name: 'style-13',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-14',
    patterns: ['pattern-6', 'pattern-2', 'pattern-1'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-15',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-16',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-17',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }],
},
{
  name: 'layout-4',
  styles: [{
    name: 'style-1',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-2',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-3',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-4',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-5',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-6',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-7',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-8',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-9',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-10',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-11',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-12',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-13',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-14',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-15',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-16',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-17',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }],
},
{
  name: 'layout-5',
  styles: [{
    name: 'style-1',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-2',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-3',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-4',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-5',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-6',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-7',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-8',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-9',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-10',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-11',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-12',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-2']
  }, {
    name: 'style-13',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-14',
    patterns: ['pattern-4', 'pattern-3'],
    hues: ['hue-1', 'hue-2']
  }, {
    name: 'style-15',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-16',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-2']
  }, {
    name: 'style-17',
    patterns: ['pattern-1', 'pattern-2'],
    hues: ['hue-1', 'hue-2']
  }],
}]


export const mapStyles2 = {
  'style-1': 'mapbox://styles/microtest/cka77w1a800ba1ip5acme9lts',
  'style-2': 'mapbox://styles/microtest/cka5jwews16h31imom6ze6jmi',
  'style-3': 'mapbox://styles/microtest/cka847ruo25e71in5ikwd3wn0',
  'style-4': 'mapbox://styles/microtest/cka84d5c20vr31ip50p21tcpb',
  'style-5': 'mapbox://styles/microtest/cka84i3rx0b3b1ip8fc91pnfp',
  'style-6': 'mapbox://styles/microtest/cka84nlp125nj1iogn6egssot',
  'style-7': 'mapbox://styles/microtest/cka82smob015u1iqphxwfm1bq',
  'style-8': 'mapbox://styles/microtest/cka837v8b00da1ipop3tz7oj3',
  'style-9': 'mapbox://styles/microtest/cka83gd0005kf1ipnczlby9vx',
  'style-10': 'mapbox://styles/microtest/cka8bww5o265z1iqulpw55w8b',
  'style-11': 'mapbox://styles/microtest/cka8c0h061srs1iqku3o020dd',
  'style-12': 'mapbox://styles/microtest/cka8c3uek08hh1inrmjqzk1uq',
  'style-13': 'mapbox://styles/microtest/cka8c6u5v2cj61imy5540xs09',
  'style-14': 'mapbox://styles/microtest/cka84pmj901qu1io8qht9pu1o',
  'style-15': 'mapbox://styles/microtest/ckad2yqn902vi1inz4oatbd80',
  'style-16': 'mapbox://styles/microtest/ckad2x36302gc1jpr6qnjmmug',
  'style-17': 'mapbox://styles/microtest/ckafax6un279o1ipuaeyk30vf',
}

export const backgrounds = [
  {
    name: 'Brak',
    src: '',
  },
  {
    name: 'Biała cegła',
    src: background1,
  },
  {
    name: 'Chropowata ściana',
    src: background2
  }
];

export const api = '';
// export const api = 'http://localhost:5000';

export const mapBoxPublicKey = 'pk.eyJ1IjoibWFyY284OTEyIiwiYSI6ImNrOWxnc2ptcDA0NzAzZnMxaGx6eW9xcGEifQ.2_z7WMtvgn9J_60TJYKBoA';

export const TYPES = [
  {
    name: 'poster',
    options: ['mattepaper', 'photoposter'],
    sizes: [
      {
        price: 38,
        width: 21,
        height: 30,
        multiplier: 14
      },
      {
        price: 41,
        width: 30,
        height: 42,
        multiplier: 11
      },
      {
        price: 47,
        width: 42,
        height: 60,
        multiplier: 8
      },
      {
        price: 58,
        width: 50,
        height: 70,
        multiplier: 7
      },
      {
        price: 70,
        width: 60,
        height: 84,
        multiplier: 6
      },
      {
        price: 79,
        width: 61,
        height: 91,
        multiplier: 6
      },
      {
        price: 85,
        width: 70,
        height: 100,
        multiplier: 5.5
      },
      {
        price: 92,
        width: 84,
        height: 119,
        multiplier: 5
      },
      {
        price: 99,
        width: 100,
        height: 140,
        multiplier: 4.5
      }
    ]
  },
  {
    name: 'canvas',
    options: ['canvas'],
    sizes: [
      {
        price: 65,
        width: 20,
        height: 30,
        multiplier: 14
      },
      {
        price: 71,
        width: 30,
        height: 40,
        multiplier: 11
      },
      {
        price: 94,
        width: 50,
        height: 70,
        multiplier: 7
      },
      {
        price: 119,
        width: 70,
        height: 70,
        multiplier: 6
      },
      {
        price: 130,
        width: 70,
        height: 100,
        multiplier: 5.5
      },
      {
        price: 145,
        width: 100,
        height: 100,
        multiplier: 4.5
      },
      {
        price: 159,
        width: 100,
        height: 140,
        multiplier: 4.5
      }
    ]
  },
  // {
  //   name: 'board',
  //   options: ['mattepaper', 'photoposter'],
  //   sizes: [
  //     {
  //       price: 116,
  //       width: 60,
  //       height: 85,
  //       multiplier: 6
  //     }
  //   ]
  // }
];

export const TRANSLATES = {
  poster: 'plakat',
  canvas: 'obraz na płótnie',
  mattepaper: 'Papier matowy',
  board: 'plansze',
  photoposter: 'Papier błyszczący fotograficzny',
  vertical: 'Pionowy',
  horizontal: 'Poziomy',
  'layout-1': 'Standard',
  'layout-2': 'Full Print',
  'layout-3': 'W Punkt',
  'layout-4': 'Ramka',
  'layout-5': 'Podwójna Ramka',
  'style-1': 'BLACK / ORANGE',
  'style-2': 'Bardziej białe niż czarne',
  'style-3': 'WHITE / ORANGE',
  'style-4': 'Architekt',
  'style-5': 'Modest',
  'style-6': 'Moro',
  'style-7': 'Neo',
  'style-8': 'DARK GRAY MODE',
  'style-9': 'Odcienie szarości',
  'style-10': 'Winter is coming',
  'style-11': 'Light Mode',
  'style-12': 'Dark Mode',
  'style-13': 'Gold Mode',
  'style-14': 'Klasyczna Mapa',
  'style-15': 'Florencja nocą',
  'style-16': 'Hogwart',
  'style-17': 'Dziki Zachód',
  'pattern-1': 'Układ 1',
  'pattern-2': 'Układ 2',
  'pattern-3': 'Układ 3',
  'pattern-4': 'Układ 4',
  'pattern-5': 'Układ 5',
  'pattern-6': 'Układ 6',
  'pattern-7': 'Układ 7',
  'hue-1': 'papier matowy',
  'hue-2': 'papier błyszczący'
};
