import React, {useState} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import type1 from '../../../../assets/images/type1.jpeg';
import type2 from '../../../../assets/images/type2.jpeg';
import type3 from '../../../../assets/images/type3.jpeg';

export const DetailDialog = () => {
  const [open, setOpen] = useState(false);

  const images = [{
    title: 'Papier matowy - PLAKAT',
    text: 'Mocno matowe wykończenie w połączeniu w najlepszej jakości drukiem sprawia, że produkt zaspokoi gusta każdego Klienta. Idealnie pasuje do antyram, ramek do obrazów, tablic korkowych czy nawet jako samodzielny plakat. Gramatura papieru to 180g. Powłoka matowa.',
    src: type3
  }, {
    title: 'Papier błyszczący - PLAKAT',
    text: 'Jednolita, gładka i mocno świecąca powierzchnia idealnie upodabnia się do odbitki fotograficznej. Dzięki najlepszej jakości druku oddającego pełną głębie i odwzorowanie kolorów sprawia, że nasz produkt znajdzie zastosowanie wszędzie tam, gdzie Klient chce mieć idealny design. Produkt można montować samodzielnie jako plakat lub w antyramach, ramkach do obrazów czy też tablicach korkowych. Gramatura papieru to 220g. Powłoka błyszcząca.',
    src: type2
  }, {
    title: 'Obraz na płótnie',
    text: 'Canvas, tak nazywa się ten najwyższej klasy materiał który idealnie przypomina swoją strukturą płótno malarskie. Całość naciągamy na drewniane krosno dzięki czemu Klient otrzymuje od nas już w pełni gotowy produkt, który od razu może wieszać na ścianie. Gramatura płótna to 300g. Powłoka matowa, struktura płótna.',
    src: type1
  }]

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div className="dialog-wrapper">
      <p className="standard-paragraph light-gray-color line-height-1-2 d-block mb-15">
        Kliknij <span onClick={onOpen} className="standard-paragraph cursor-pointer light-green-color font-weight-bold">tutaj</span> po wiecej informacji. 
      </p>
     
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color" id="alert-dialog-title">Różne materiały - różne zastosowania</DialogTitle>
        <DialogContent>  
          <div className="container-fluid p-0">
            {images?.map((elem, key) => (
              <div key={key} className="row mb-20">
                <div className="col-lg-6 ">
                  <img className="w-100" alt="simple" src={elem.src} />
                </div>
                <div className="col-lg-6">
                  <h3 className="font-weight-regular secondary-font text-uppercase standard-header almost-black-color mb-1">{elem.title}</h3>
                  <p className="standard-paragraph light-gray-color line-height-1-2 mb-15">{elem.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="container-fluid pt-3 pb-2 p-0">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end">
                <button onClick={onClose} className="standard-paragraph white-color border-0 almost-black-bg border-none cursor-pointer secondary-font text-uppercase font-weight-regular px-4 py-3">Zamknij</button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}