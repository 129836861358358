import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Creator } from './pages/creator/Creator'

export const Routing = () => (
  <Switch>
    <Route exact path='*' component={Creator} />
  </Switch>
);
